import React from "react"
import { PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.ico"
import "../style/main.scss"

const LegalNoticePage: React.FC<PageProps> = () => (
	<>
		<Helmet>
			<meta charSet='utf-8' />
			<title>Airbeone</title>
			<html lang='fr' />
			<meta name='description' content='Airbeone' />
			<link rel='icon' href={favicon} />
		</Helmet>
		<main className='legal-notice'>
			<h2>MENTIONS LEGALES SITE AIRBEONE.COM</h2>
			<b>EDITION</b>
			<div>Société Airbeone</div> <div>Numéro de SIRET : 50992837000048</div>
			<div>Responsable éditorial : Rémi BONIN</div>
			<div>7 rue Paul Bert 63000 CLERMONT FERRAND</div>
			<div>Email : remi@airbeone.com</div>
			<br />
			<b>CREATION ARTISTIQUE</b> <div>Com&Gax</div>
			<div>Responsable : Pierre PIROCHE</div>
			<div>www.comgax.com</div>
			<br />
			<b>DEVELOPPEMENT</b>
			<div>WildCöde </div>
			<div>Responsable : Blandine RONDEL </div>
			<div>Site Web : https://www.malt.fr/profile/blandinerondel</div>
			<br />
			<b>HEBERGEMENT</b>
			<div>02 Switch</div> <div>Site Web : https://o2switch.fr</div>
			<br />
			<b>CONDITIONS D’UTILISATIONS</b>
			<p>
				Le site (www.airbeone.com) est proposé en différents langages web pour
				un meilleur confort d’utilisation et un graphisme plus agréable. Nous
				vous recommandons de recourir à des navigateurs modernes comme Internet
				explorer, Safari, Firefox, Google Chrome, etc…
			</p>
			<p>
				La société AIRBEONE met en œuvre tous les moyens dont elle dispose, pour
				assurer une information fiable et une mise à jour fiable de son site
				internet. Toutefois, des erreurs ou omissions peuvent survenir. Le
				visiteur devra donc s’assurer de l’exactitude des informations auprès de
				la société AIRBEONE, et signaler toutes modifications du site qu’il
				jugerait utile. La société AIRBEONE n’est en aucun cas responsable de
				l’utilisation faite de ces informations, et de tout préjudice direct ou
				indirect pouvant en découler.
			</p>
			<br />
			<b>COOKIES </b>
			<p>
				Le site www.airbeone.com peut être amené à vous demander l’acceptation
				des cookies pour des besoins de statistiques et d’affichage. Les
				«cookies» sont des éléments d’information qu’un site Internet transmet
				au disque dur d’un ordinateur individuel afin de garder un
				enregistrement du passage ; les mots de passe ne sont pas gardés dans
				les « cookies ». Si vous ne souhaitez pas recevoir de « cookie » vous
				pouvez utiliser l’option sur votre navigateur qui rend indisponible les
				«cookies».
				<br /> Les cookies sont gérés par votre navigateur internet et sont
				anonymes, nous ne collectons pas de données personnelles.
			</p>
			<br />
			<b>PROPRIETE INTELLECTUELLE</b>
			<p>
				Tout le contenu du présent site www.airbeone.com incluant, de façon non
				limitative, les graphismes, images, textes, vidéos, animations, sons,
				logos, gifs et icônes ainsi que leur mise en forme sont la propriété
				exclusive de la société à l’exception des marques, logos ou contenus
				appartenant à d’autres sociétés partenaires ou auteurs. Toute
				reproduction, distribution, modification, adaptation, retransmission ou
				publication, même partielle, de ces différents éléments est strictement
				interdite sans l’accord exprès par écrit de la société AIRBEONE. Cette
				représentation ou reproduction, par quelque procédé que ce soit,
				constitue une contrefaçon sanctionnée par les articles L.335-2 et
				suivants du Code de la propriété intellectuelle. Le non-respect de cette
				interdiction constitue une contrefaçon pouvant engager la responsabilité
				civile et pénale du contrefacteur. En outre, les propriétaires des
				contenus copiés pourraient intenter une action en justice à votre
				encontre.
			</p>
			<br />
			<b>DECLARATION A LA CNIL</b>
			<p>
				Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi
				2004-801 du 6 août 2004 relative à la protection des personnes physiques
				à l’égard des traitements de données à caractère personnel) relative à
				l’informatique, aux fichiers et aux libertés, ce site a fait l’objet
				d’une déclaration 1656629 auprès de la Commission nationale de
				l’informatique et des libertés (www.cnil.fr).
			</p>
			<br /> <b>DONNEES PERSONNELLES</b>
			<p>
				De manière générale, vous n’êtes pas tenu de nous communiquer vos
				données personnelles lorsque vous visitez notre site www.airbeone.com.
			</p>
			<p>
				Cependant, ce principe comporte certaines exceptions. En effet, pour
				certains services proposés par notre site, vous pouvez être amenés à
				nous communiquer certaines données telles que : votre nom, votre
				fonction, le nom de votre société, votre adresse électronique, et votre
				numéro de téléphone. Tel est le cas lorsque vous remplissez le
				formulaire qui vous est proposé en ligne, dans la rubrique contact.
			</p>
			<p>
				Enfin, nous pouvons collecter de manière automatique certaines
				informations vous concernant lors d’une simple navigation sur notre site
				internet, notamment : des informations concernant l’utilisation de notre
				site, comme les zones que vous visitez et les services auxquels vous
				accédez, votre adresse IP, le type de votre navigateur, vos temps
				d’accès.
			</p>
			<p>
				De telles informations sont utilisées exclusivement à des fins de
				statistiques internes, de manière à améliorer la qualité des services
				qui vous sont proposés. Les bases de données sont protégées par les
				dispositions de la loi du 1er juillet 1998 transposant la directive 96/9
				du 11 mars 1996 relative à la protection juridique des bases de données.
			</p>
		</main>
	</>
)

export default LegalNoticePage
